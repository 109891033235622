export interface UpdateProfileProps {
  name?: string
  avatar?: File
}

export class AuthManagementService {
  private readonly serviceUrl: string

  constructor(serviceUrl?: string) {
    if (!serviceUrl) {
      throw new Error('Service URL is required')
    }

    this.serviceUrl = serviceUrl
  }

  public async updateProfile(idToken: string, profile: UpdateProfileProps): Promise<void> {
    // save avatar first
    const formData = new FormData()

    if (profile.avatar) {
      formData.append('image', profile.avatar)
    }

    if (profile.name) {
      formData.append('name', profile.name)
    }

    const profileResponse = await fetch(`${this.serviceUrl}/update-profile`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      body: formData,
    })

    if (!profileResponse.ok) {
      throw new Error('Error updating profile')
    }
  }

  public async completeRegistration(idToken: string): Promise<void> {
    const response = await fetch(`${this.serviceUrl}/complete-registration`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
    })

    if (!response.ok) {
      throw new Error('Error completing registration')
    }
  }
}
